import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { apiSollar } from "../../../services/axios";
import {
  loadFailure,
  loadSuccess,
  loadSuccessExamUrl,
  loadSuccessLogs,
} from "./actions";
import { DataSyncTypes } from "./types";
import openURL from "../../../utils/openURL";

export interface RequestParamsDataSync {
  external_patient_id: string | number;
  data_sync_type: "DOCUMENT" | "LAB_EXAM" | "IMAGE_EXAM";
  in_iframe?: boolean;
  initial_date?: string;
  end_date?: string;
  data_sync_id?: number;
  page?: number | string;
  limit?: number | string;
}

export interface LoadRequestParams {
  type: typeof DataSyncTypes.LOAD_REQUEST;
  payload: RequestParamsDataSync;
}

export function* getIntegratedDocuments(data: LoadRequestParams) {
  try {
    const response: AxiosResponse = yield call(
      apiSollar.get as any,
      `/data-sync`,
      {
        responseType: data.payload.in_iframe ? "blob" : "json",
        params: data.payload,
      }
    );
    if (
      data.payload.data_sync_type === "LAB_EXAM" ||
      data.payload.data_sync_id
    ) {
      if (!data.payload?.in_iframe) {
        yield put(loadSuccessExamUrl(response.data));
        openURL(response.data.url);
      } else {
        const pdfBlob = response.data;
        const pdfUrl = URL.createObjectURL(pdfBlob);
        response.data.url = pdfUrl;

        yield put(loadSuccessExamUrl(response.data));
      }
    } else {
      yield put(loadSuccess(response.data));
    }
  } catch (err) {
    yield put(loadFailure());
    toast.error("Não foi possível carregar os documentos de integração.");
  }
}

export function* getIntegratedDocumentsLogs(data: LoadRequestParams) {
  try {
    const response: AxiosResponse = yield call(
      apiSollar.get as any,
      `/data-sync/log`,
      {
        params: data.payload,
      }
    );
    yield put(loadSuccessLogs(response.data));
  } catch (err) {
    yield put(loadFailure());
    toast.error("Não foi possível carregar os documentos de integração.");
  }
}
