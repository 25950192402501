import { Box, Button, Grid, Typography, useTheme, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import TextField from "../../../../../components/FormFields/TextField";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import AccordionComponent from "../../../../../components/AccordionComponent";
import DocumentIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { format, parseISO } from "date-fns";
import LOCALSTORAGE from "../../../../../helpers/constants/localStorage";
import {
  loadRequestCancelDocument,
  loadRequestCreateDocument
} from "../../../../../store/ducks/telemedicineAttendances/actions";
import AutocompleteField from "../../../../../components/FormFields/AutocompleteField";
import { loadSelectSpecialityRequest as loadSpecialities } from "../../../../../store/ducks/opinions/actions";
// import { apiSollar } from "../../../../../services/axios";
// import { toast } from "react-toastify";
interface IOpinionResponseProps {
  telemedicineId: string;
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  createNewDocument: boolean;
  setCreateNewDocument?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCall?: React.Dispatch<React.SetStateAction<boolean>>;
  inCall?: boolean;
  sx?: SxProps;
}

export default function OpinionResponse({
  telemedicineId,
  setRefetch,
  createNewDocument = false,
  setCreateNewDocument,
  setOpenCall,
  inCall,
  sx,
}: IOpinionResponseProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [cancelDocument, setCancelDocument] = useState(false);
  const userLogged = localStorage.getItem(LOCALSTORAGE.USER_ID);

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      response: "",
      realization_type: "AVALIAÇÃO",
      alerts: null,
      describe: null
    },
  });

  const requestOpinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  const cancelForm = useForm({
    defaultValues: {
      canceled_reason: "",
    },
  });

  useEffect(() => {
    dispatch(loadSpecialities("form"));
  }, []);

  return (
    <Box sx={sx}>
      {createNewDocument ? (
        <Grid
          container
          spacing={1.5}
          sx={{
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
          component="form"
          onSubmit={handleSubmit((values) => {
            values.realization_type = "AVALIAÇÃO"
            values.alerts = telemedicineAttendancesState.data.attendance?.medical_opinion_alerts
            values.describe = telemedicineAttendancesState.data.attendance?.medical_opinion_describe
            dispatch(
              loadRequestCreateDocument({
                content: values,
                document_type: "MedicalOpinion",
                telemedicine_attendance_id: telemedicineId,
              })
            );
            if (inCall) {
              setOpenCall(false);
            } else {
              setCreateNewDocument(false);
            }
            setRefetch(true);
            reset();
          })}
        >
        <Grid container paddingX={2} mb={2} alignItems="center">
          <Grid item xs={12} display="flex" gap={1}>
            <Typography fontSize={16} fontWeight={550} mb={2}>
              Alertas:
            </Typography>
            <Typography fontSize={16} mb={2}>
            {
              telemedicineAttendancesState.data.attendance?.medical_opinion_alerts?.trim()
              ? telemedicineAttendancesState.data.attendance?.medical_opinion_alerts
              : "NÃO INFORMADO"
            }
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" gap={1}>
            <Typography fontSize={16} fontWeight={550} mb={2} >
              Parecer:
            </Typography>
            <Typography  fontSize={16} mb={2}>
              {telemedicineAttendancesState.data.attendance?.medical_opinion_describe || "Não informado"}
            </Typography>

          </Grid>
          <Grid item xs={12} display="flex" gap={1.5}>
            <TextField
              control={control}
              name="realization_type"
              type="text"
              label="Realização de:"
              value={"AVALIAÇÃO"}
              disabled
            />
            {/* <TextField
              control={control}
              name="opinion_specialty"
              type="text"
              label="Especialidade"
              required
            /> */}
            <AutocompleteField
              control={control}
              name="opinion_specialty"
              label="Especialidade"
              options={requestOpinionState.specialities}
              optionLabelKey={"name"}
              // customOnChange={() => setValue("specialties", [])}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Resposta do parecer"
            control={control}
            name="response"
            multiline
            minRows={6}
            required
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="start">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
      </Grid> ) :
        (
          telemedicineAttendancesState?.documentList?.items?.medicalOpinion?.map(
            (document) => {
              console.log(`document`, document);
              const documentCanceled = document?.canceled
                ? { textDecoration: "line-through" }
                : { textDecoration: "none" };
              return (
                <AccordionComponent
                  configs={[
                    {
                      title: (
                        <Box display="flex" gap={0.3}>
                          <DocumentIcon />
                          <Typography sx={documentCanceled}>
                            {format(
                              parseISO(document?.created_at),
                              "dd/MM/yyyy 'às' HH:mm"
                            )}
                          </Typography>
                        </Box>
                      ),
                      body: (
                        <Box>
                          <Box display="flex" gap={0.4} sx={documentCanceled}>
                            <Typography fontWeight={550}>Realização de::</Typography>
                            <Typography>{document?.content?.realization_type}</Typography>
                          </Box>
                          <Box display="flex" gap={0.4} sx={documentCanceled}>
                            <Typography fontWeight={550}>Especialidade:</Typography>
                            <Typography>
                              {document?.content?.opinion_specialty.name}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>Resposta:</Typography>
                            <Typography>
                              {document?.content?.response}
                            </Typography>
                          </Box>
                          {document?.canceled_at && document?.canceled_reason && (
                            <>
                              <Box display={"flex"} gap={0.4}>
                                <Typography fontWeight={550}>
                                  Cancelado em:
                                </Typography>
                                <Typography>
                                  {format(
                                    parseISO(document?.canceled_at),
                                    "dd/MM/yyyy 'às' HH:mm"
                                  )}
                                </Typography>
                              </Box>
                              <Box display={"flex"} gap={0.4}>
                                <Typography fontWeight={550}>
                                  Razão do Cancelamento:
                                </Typography>
                                <Typography>
                                  {document?.canceled_reason}
                                </Typography>
                              </Box>
                            </>
                          )}
                          {document?.created_by == userLogged &&
                            !document.canceled && (
                              <Button
                                sx={{ width: 110, mt: 1.5 }}
                                variant="contained"
                                color={cancelDocument ? "primary" : "error"}
                                onClick={() => setCancelDocument(!cancelDocument)}
                              >
                                {cancelDocument ? "Voltar" : "Cancelar"}
                              </Button>
                            )}
                          {cancelDocument && document?.created_by == userLogged && (
                            <Grid
                              sx={{
                                backgroundColor:
                                  theme.palette.whiteBackgroud.main,
                                color: theme.palette.text.primary,
                                paddingX: 3,
                                paddingY: 3,
                                minHeight: "48px",
                              }}
                              container
                              spacing={2}
                              component="form"
                              onSubmit={cancelForm.handleSubmit((values) => {
                                dispatch(
                                  loadRequestCancelDocument({
                                    canceled_reason: values.canceled_reason,
                                    _id: document._id,
                                  })
                                );
                                setCancelDocument(!cancelDocument);
                                setRefetch(true);
                                cancelForm.reset();
                              })}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  label="Razão do cancelamento"
                                  control={cancelForm.control}
                                  name="canceled_reason"
                                  multiline
                                  minRows={3}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  color="error"
                                  type="submit"
                                  variant="contained"
                                  sx={{ maxWidth: 180, width: "100%" }}
                                >
                                  Confirmar
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      ),
                    },
                  ]}
                  defaultOpen={false}
                />
              );
            }
          )
        )
      }

    </Box >
  );
}
