import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { checkEditPermission } from "../../../utils/permissions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import NoPermission from "../../../components/Erros/NoPermission";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import UserIcon from "../../../components/Icons/User";
import TabTittle from "../../../components/Text/TabTittle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { cleanUpMask, formatWithMask } from "../../../utils/mask";
import TextField from "../../../components/FormFields/TextField";
import RegexOf from "../../../utils/regex";
import { ufOptions } from "../../../utils/optionsToFields";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import { apiSollar } from "../../../services/axios";
import { toast } from "react-toastify";
import {
  loadProfessionsRequest,
  loadRequestByExternalId,
  loadRequestStoreUser,
  loadRequestUpdateUser,
  loadUserById,
} from "../../../store/ducks/users/actions";
import { loadSelectSpecialityRequest as loadSpecialities } from "../../../store/ducks/opinions/actions";
import ProfessionalIcon from "../../../components/Icons/Professional";
import { useNavigate, useParams } from "react-router-dom";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";
import { CompanyUserLinkInterface } from "../../../store/ducks/users/types";
import SwitchField from "../../../components/FormFields/SwitchField";

interface UserFormProps {
  mode: "edit" | "create";
}

async function getCompanies(params?: any) {
  try {
    const response = await apiSollar.get("/companies/list", {
      params,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export default function UserForm({ mode }: UserFormProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const companyId = localStorage.getItem(LOCALSTORAGE.COMPANY_SELECTED);
  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const requestOpinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  const userState = useSelector((state: ApplicationState) => state.users);
  const customerState = useSelector((state: ApplicationState) => state.customers);
  const [disableFields, setDisableFields] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    fiscal_number: "",
    user_external_id: "",
    username: "",
    main_specialty: null,
    specialties: [],
    function: null,
    council: "",
    council_state: null,
    council_number: "",
    active: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .required("Campo obrigatório")
      .email("Formato de e-mail incorreto"),
    fiscal_number: Yup.string().required("Campo obrigatório").nullable(),
    user_external_id: Yup.string().required("Campo obrigatório").nullable(),
    username: Yup.string().required("Campo obrigatório").nullable(),
    main_specialty: Yup.object().required("Campo obrigatório").nullable(),
    specialties: Yup.array().nullable(),
    function: Yup.object().required("Campo obrigatório").nullable(),
    council: Yup.string().required("Campo obrigatório").nullable(),
    council_state: Yup.object().required("Campo obrigatório").nullable(),
    council_number: Yup.string().required("Campo obrigatório").nullable(),
    active: Yup.boolean().nullable(),
  });

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const mainSpecialty = watch("main_specialty");

  const otherSpecialties = requestOpinionState.specialities.filter(
    (specialty: any) => specialty.id !== mainSpecialty?.id
  );

  const onSubmit = (data: any) => {
    data.function = data.function?.name;
    data.specialties_mapping = {
      main: {
        id: String(data.main_specialty.id),
        name: data.main_specialty.name,
      },
      others: data.specialties?.map((specialty: any) => ({
        id: String(specialty.id),
        name: specialty.name,
      })),
    };
    data.main_specialty = data.main_specialty?.name;
    data.council_state = data.council_state?.describe;
    data.specialties = data.specialties
      .map((specialty) => specialty.name)
      .join(", ");
    data.fiscal_number = cleanUpMask(data.fiscal_number, "", ["_", "-", "."]);
    if (mode === "edit") {
      data._id = params.id;
      if (resetPassword) {
        data.reset_password = true;
      }
      dispatch(loadRequestUpdateUser(data));
    } else {
      dispatch(loadRequestStoreUser(data));
    }

    reset();
  };

  useEffect(() => {
    if (userState.success && !userState.external) {
      toast.success(
        `Usuário ${mode === "edit" ? "editado" : "criado"} com sucesso`
      );
      navigate("/user");
    }
  }, [userState.success]);

  useEffect(() => {
    dispatch(loadSpecialities("form"));
    dispatch(loadProfessionsRequest());
    if (!customerState.data?.settings?.enable_integration_edit_in_user_registry) {
      setDisableFields(true);
    }
  }, []);

  useEffect(() => {
    if (mode === "edit" && params.id !== userState.data._id) {
      dispatch(loadUserById(params.id, "user"));
    }
  }, [params]);

  useEffect(() => {
    if (mode === "edit") {
      dispatch(loadRequestByExternalId({ id: userState.data.username }))
    }
  }, [userState.data])

  useEffect(() => {
    if (userState.data._id === params.id && mode === "edit") {
      const actualCompanyLink = userState.data.companies_links.find(
        (link: CompanyUserLinkInterface) => link.companie_id._id === companyId
      );
      const specialties = actualCompanyLink.specialties?.split(",");
      const specialtiesToField = specialties.map((specialty: string) => ({
        name: specialty,
      }));
      reset({
        name: userState.data.name,
        email: userState.data.email,
        fiscal_number: formatWithMask({
          text: userState.data.fiscal_number,
          mask: RegexOf.cpf,
        }).masked,
        username: actualCompanyLink.username,
        user_external_id: actualCompanyLink.user_external_id,
        main_specialty: { name: actualCompanyLink.main_specialty },
        specialties: specialtiesToField,
        function: { name: actualCompanyLink.function },
        council: actualCompanyLink.council,
        council_number: actualCompanyLink.council_number,
        council_state: { describe: actualCompanyLink.council_state },
        active: userState.data.active,
      });
    }
  }, [userState.data]);

  useEffect(() => {
    if (userState.external) {
      reset({
        username: userState.external.user_id || "",
        user_external_id: userState.external.provider_id || "",
        name: userState.external.name || "",
        email: userState.external.ds_email || "",
        fiscal_number:
          formatWithMask({
            text: userState.external.fiscal_number,
            mask: RegexOf.cpf,
          }).masked || "",
        main_specialty:
          {
            id: userState.external.main_specialty_id,
            name: userState.external.main_specialty,
          } || null,
        function:
          {
            id: userState.external.user_function_id,
            name: userState.external.user_function,
          } || null,
        council: userState.external.council || "",
        council_state: { describe: userState.external.council_state } || null,
        council_number: userState.external.council_number || "",
      });
    }
    if (!userState.external) {
      reset(initialValues);
    }
  }, [userState.external]);

  return (
    <Sidebar>
      {checkEditPermission("user", JSON.stringify(rightsOfLayoutState)) ? (
        <Container>
          <TabTittle
            tittle={mode === "edit" ? "Editar Usuário" : "Novo Usuário"}
          />
          <Grid
            component="form"
            container
            spacing={2}
            sx={{
              background: "rgb(245, 245, 245)",
              borderRadius: 1,
              padding: 1.5,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item container spacing={1.5} xs={12}>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                gap={1}
                mb={1}
              >
                <UserIcon fill={theme.palette.primary.main} noCircle />
                <Typography variant="body2" fontWeight={600}>
                  Dados Pessoais
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={1.5}>
                <Grid item md={3} xs={6}>
                  <TextField
                    control={control}
                    name="username"
                    label="Nome de usuário"
                    disabled={disableFields && mode === "edit"}
                    required
                    onBlur={(value) => {
                      dispatch(loadRequestByExternalId({ id: value }));
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    control={control}
                    name="user_external_id"
                    label="Cód. Prestador"
                    disabled={disableFields}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item md={5} xs={12}>
                <TextField
                  control={control}
                  name="name"
                  label="Nome"
                  disabled={disableFields}
                  required
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  control={control}
                  name="email"
                  label="Email"
                  disabled={disableFields}
                  required
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  control={control}
                  name="fiscal_number"
                  label="CPF"
                  mask={RegexOf.cpf}
                  disabled={disableFields}
                  required
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1.7 }} variant="fullWidth" />
            <Grid item container spacing={1.5} xs={12}>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                gap={1}
                mb={1}
              >
                <ProfessionalIcon fill={theme.palette.primary.main} />
                <Typography variant="body2" fontWeight={600}>
                  Dados Profissionais
                </Typography>
                <Divider />
              </Grid>
              <Grid item md={6} xs={12}>
                <AutocompleteField
                  control={control}
                  name="main_specialty"
                  label="Especialidade Principal"
                  options={requestOpinionState.specialities}
                  optionLabelKey={"name"}
                  disabled={disableFields}
                  customOnChange={() => setValue("specialties", [])}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AutocompleteField
                  control={control}
                  name="specialties"
                  label="Outras Especialidades"
                  options={otherSpecialties}
                  disabled={disableFields}
                  optionLabelKey={"name"}
                  multiple
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <AutocompleteField
                  control={control}
                  name="function"
                  label="Função"
                  options={userState.data?.professions}
                  disabled={disableFields}
                  optionLabelKey={"name"}
                  required
                />
              </Grid>
              <Grid item md={2.5} xs={6}>
                <TextField
                  control={control}
                  name="council"
                  label="Conselho"
                  disabled={disableFields}
                  type="text"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <AutocompleteField
                  control={control}
                  name="council_state"
                  label="UF Conselho"
                  options={ufOptions}
                  disabled={disableFields}
                  optionLabelKey={"describe"}
                />
              </Grid>
              <Grid item md={2.5} xs={12}>
                <TextField
                  control={control}
                  name="council_number"
                  disabled={disableFields}
                  label="Código Conselho"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={mode === "edit" ? "space-between" : "flex-end"}
              gap={2.5}
              mb={1}
            >
              {mode === "edit" && (
                <Box display={"flex"} gap={2}>
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={() => setResetPassword(true)}
                  >
                    Resetar senha
                  </Button>
                  <SwitchField control={control} name="active" label="Desativar / Ativar" />
                </Box>
              )}
              <Box display="flex" gap={1}>
                <Button
                  onClick={() => {
                    navigate("/user");
                    reset();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {mode === "create" ? "Cadastrar" : "Atualizar"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
